import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tm-calendar__wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_calendar_header_toolbar = _resolveComponent("tm-calendar-header-toolbar")!
  const _component_tm_calendar_day_event = _resolveComponent("tm-calendar-day-event")!
  const _component_full_calendar = _resolveComponent("full-calendar")!
  const _component_tm_scrollbar_x_shadows = _resolveComponent("tm-scrollbar-x-shadows")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.hideHeaderToolbar)
      ? (_openBlock(), _createBlock(_component_tm_calendar_header_toolbar, {
          key: 0,
          class: "mb-4",
          view: _ctx.calendarView,
          title: _ctx.viewTitle,
          onTodayClick: _ctx.onToday,
          onPrevClick: _ctx.onPrev,
          onNextClick: _ctx.onNext,
          "onUpdate:view": _ctx.updateView
        }, null, 8, ["view", "title", "onTodayClick", "onPrevClick", "onNextClick", "onUpdate:view"]))
      : _createCommentVNode("", true),
    _createVNode(_component_tm_scrollbar_x_shadows, null, {
      default: _withCtx(() => [
        _createVNode(_component_full_calendar, {
          ref: "calendar",
          options: _ctx.calendarOptions,
          class: "tm-calendar"
        }, {
          eventContent: _withCtx(({ event }) => [
            _createVNode(_component_tm_calendar_day_event, {
              content: event.title
            }, null, 8, ["content"])
          ]),
          _: 1
        }, 8, ["options"])
      ]),
      _: 1
    })
  ]))
}