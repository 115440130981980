
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmToggleButtons from '@/components/shared/TmToggleButtons.vue'
import type { CalendarViewType } from '@/definitions/shared/calendar/types'
import type { PropType } from '@vue/runtime-core'

export default defineComponent({
  components: {
    TmToggleButtons,
    TmButton,
  },
  props: {
    view: {
      type: String as PropType<CalendarViewType>,
      default: 'dayGridMonth',
    },
    title: {
      type: String,
    },
  },
  emits: ['update:view', 'today-click', 'prev-click', 'next-click'],
  setup(props, context) {
    const updateView = (val: CalendarViewType) => {
      context.emit('update:view', val)
    }
    // @todo: add real week index
    const weekIndex = ref(11)

    return {
      updateView,
      weekIndex,
    }
  },
})
