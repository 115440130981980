
import type { PropType } from 'vue'
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from 'vue'
import '@fullcalendar/core/vdom'
import type { CalendarOptions, CalendarApi, CalendarData } from '@fullcalendar/vue3'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import TmCalendarHeaderToolbar from '@/components/shared/calendar/TmCalendarHeaderToolbar.vue'
import TmCalendarDayEvent from '@/components/shared/calendar/TmCalendarDayEvent.vue'
import type { CalendarViewType } from '@/definitions/shared/calendar/types'
import { useCalendar } from '@/compositions/calendar'
import TmScrollbarXShadows from '@/components/shared/tmScrollbar/TmScrollbarXShadows.vue'

export default defineComponent({
  components: {
    TmScrollbarXShadows,
    TmCalendarDayEvent,
    TmCalendarHeaderToolbar,
    FullCalendar,
  },
  props: {
    hideHeaderToolbar: {
      type: Boolean,
      default: false,
    },
    initialView: {
      type: String as PropType<CalendarViewType>,
      default: 'dayGridMonth',
    },
  },
  setup(props) {
    const { getMonthEvents } = useCalendar()
    const monthCalendarHeight = ref(970)
    const theadHeight = 37
    const calendar = ref()
    const viewTitle = ref<string>('')
    const calendarView = ref(props.initialView)
    const onResize = () => {
      const { offsetWidth } = calendar.value.$el
      const newHeight = offsetWidth / 7 * 6
      monthCalendarHeight.value = theadHeight + newHeight
      updateCalendar()
    }

    const conditionalOptions = computed(() => {
      const obj: Partial<CalendarOptions> = {}
      if (['timeGridWeek', 'timeGridDay'].includes(calendarView.value)) {
        obj.dayHeaderContent = (date) => {
          const result = document.createElement('span')
          const weekDay = document.createElement('span')
          weekDay.classList.add('tm-fc-week-day')
          weekDay.innerText = date.text.substring(0, 3).toUpperCase()
          const dayNumber = document.createElement('span')
          dayNumber.classList.add('tm-fc-day-number')
          dayNumber.innerText = String(date.date.getDate())
          result.appendChild(weekDay)
          result.appendChild(dayNumber)
          return { domNodes: [result] }
        }
        obj.slotLabelFormat = (date) => {
          const isPm = date.date.hour > 11
          const hour = date.date.hour > 12 ? date.date.hour - 12 : date.date.hour
          return `${hour}:00 ${isPm ? 'pm' : 'am'}`
        }
      }
      // if (calendarView.value === 'timeGridDay') {
      //   obj.nowIndicator = true
      // }
      if (calendarView.value === 'dayGridMonth') {
        obj.contentHeight = monthCalendarHeight.value
      }
      return obj
    })
    const calendarOptions = computed<CalendarOptions>(() => ({
      plugins: [
        dayGridPlugin,
        timeGridPlugin,
      ],
      headerToolbar: {
        left: 'today prev next',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      },
      initialView: props.initialView,
      initialEvents: getMonthEvents(),
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      weekends: true,
      allDaySlot: false,
      slotDuration: '01:00:00',
      scrollTime: '08:00:00',
      nowIndicator: true,
      ...conditionalOptions.value,
    }))

    const getApi = (): CalendarApi => {
      return calendar.value.getApi()
    }
    const getCurrentDate = (): CalendarData => {
      return getApi().getCurrentData()
    }
    const updateCalendar = () => {
      viewTitle.value = getCurrentDate().viewTitle
      getApi().updateSize()
    }
    const onToday = () => {
      getApi().today()
      updateCalendar()
    }
    const onNext = () => {
      getApi().next()
      updateCalendar()
    }
    const onPrev = () => {
      getApi().prev()
      updateCalendar()
    }
    const updateView = (val: CalendarViewType) => {
      calendarView.value = val
      getApi().changeView(val)
      updateCalendar()
    }

    onMounted(() => {
      onResize()
      window.addEventListener('resize', onResize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize)
    })

    return {
      calendar,
      viewTitle,
      calendarView,
      calendarOptions,
      onToday,
      onNext,
      onPrev,
      updateView,
    }
  },
})
