import type { EventInput } from '@fullcalendar/vue3'

let eventGuid = 0
const getId = () => {
  return String(eventGuid++)
}

const getMonthEvent = (date: string): EventInput => {
  return {
    id: getId(),
    title: '24 scheduled messages',
    start: date, // 'YYYY-MM-DD'
  }
}

type UseCalendar = {
  getMonthEvents: () => EventInput[];
}

export const useCalendar = (): UseCalendar => {
  const getMonthEvents = (): EventInput[] => {
    const days = ['4', '8', '12', '15', '19', '21', '25', '30']
    const getMonth = (month: string, year = '2022') => days.map(day => `${year}-${month}-${day}T12:00:00+02:00`)
    const dates = [
      ...getMonth('08'),
      ...getMonth('09'),
      ...getMonth('10'),
      ...getMonth('11'),
      ...getMonth('12'),
    ]
    return dates.map(date => getMonthEvent(date))
  }
  return {
    getMonthEvents,
  }
}
